import http, {Service} from './common'
const resource = 'image'
class ImageService extends Service {
  uploadImage(data, values) {
    const formData = new FormData();
    const date = new Date();
    let entity = values['entity'];
    let code = values['code'];
    formData.append('image', data, entity + '_' + code + '_' + date.getTime());
    for (let key in values) {
      formData.append(key, values[key]);
    }
    const headers = { 'Content-Type': 'multipart/form-data' };
    return http.post(`/${resource}/uploadImage`, formData, { headers });
  }
  uploadImages(datas, values) {
    const formData = new FormData();
    const date = new Date();
    let entity = values['entity'];
    let code = values['code'];
    formData.append('image', datas, entity + '_' + code + '_' + date.getTime());
    for (let key in values) {
      formData.append(key, values[key]);
    }
    const headers = { 'Content-Type': 'multipart/form-data' };
    return http.post(`/${resource}/uploadImage`, formData, { headers });
  }
  loadImages(receiptPkg) {
    let uri = `/${resource}/?ref=package&code=${receiptPkg.packageCode}`
    return http.get(uri).then((response) => {
      if (Array.isArray(response.data)) {
        return response.data.map(content => {
          if (typeof content == 'string') {
              return `data:image/png;base64, ${content}`
          } else if (typeof content == 'object') {
              let res = {};
              if (content.image) {
                res.imageSrc = `data:image/png;base64, ${content.image}`
              }
              if (content.thumbnail) {
                res.thumbnailSrc = `data:image/png;base64, ${content.thumbnail}`
              }
              return res;
          }
        });
      } else if (response.data){
        `data:image/png;base64, ${response.data}`
      }
    });
  }
}
export default new ImageService(resource)
